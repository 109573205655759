<template>
    <div id="app">
        <div class="policies-container">
            <section class="policies-section">
                <h2 class="text-title">{{ $t("message.content114") }}</h2>
                <p>{{ $t("message.content115") }}</p>
                <p>{{ $t("message.content116") }}</p>
                <p>{{ $t("message.content117") }}</p>
            </section>

            <section class="section">
                <div class="image-text">
                    <h2 class="text-title-sub">{{ $t("message.content118") }}</h2>
                    <p>
                        {{ $t("message.content119") }}
                    </p>
                </div>
                <div class="text-color-h">

                </div>
                <div class="image-container">
                    <img class="img-sustainable" src="@/assets/image-service/service9.png" />
                </div>
            </section>

        </div>
        <div class="link-container">
            <template>
                <div class="policy-body-left">
                    <div v-for="policy in policies" :key="policy.id" class="policy-item-left">
                        <a @click.prevent="handleViewFile(policy.link)" class="policy-name">{{ policy.name }}</a>
                        <button class="download-button" @click="downloadFile(policy.link)">
                            <p class="download-button-text">
                                DOWNLOAD
                            </p>
                        </button>
                    </div>
                </div>
                <div class="policy-body-right">
                    <div v-for="policy in policies2" :key="policy.id" class="policy-item-right">
                        <a @click.prevent="handleViewFile(policy.link)" class="policy-name">{{ policy.name }}</a>
                        <button class="download-button" @click="downloadFile(policy.link)">
                            <p class="download-button-text">
                                DOWNLOAD
                            </p>
                        </button>
                    </div>
                </div>
            </template>
        </div>
        <div class="policies-container2">
            <section class="policies-section">
                <h2 class="text-title-sub">{{ $t("message.content120") }}</h2>
                <p>{{ $t("message.content121") }}</p>
                <p>{{ $t("message.content122") }}</p>
            </section>

            <div class="link-container2">
                <template>
                    <div class="policy-body2">
                        <div v-for="rjc in RJC" :key="rjc.id" class="policy-item">
                            <a class="policy-name2" @click="handleViewFile(rjc.link)">{{ rjc.name }}</a>
                            <button class="download-button" @click="downloadFile(rjc.link)">
                                <p class="download-button-text">
                                    DOWNLOAD
                                </p>
                            </button>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div>
            <gettouch />
        </div>
    </div>
</template>

<script>
import Gettouch from "@/components/Gettouch.vue";
export default {
    mounted() { },
    components: {
        gettouch: Gettouch,
    },
    data() {
        return {
            more: false,
            policies: [
                { id: 1, name: "MR-01 CSR POLICY.pdf", link: "/pdf/RJCPL-MR-01-CSR-POLICY.pdf" },
                { id: 2, name: "MR-02 LABOR POLICY.pdf", link: "/pdf/RJCPL-MR-02 LABOR POLICY.pdf" },
                { id: 3, name: "MR-03 OCCUPATIONAL HEALTH AND SAFEY AND ENVIRONMENTAL MANAGEMENT POLICY.pdf", link: "/pdf/RJCPL-MR-03 OCCUPATIONAL HEALTH AND SAFEY AND ENVIRONMENTAL MANAGEMENT POLICY.pdf" },
                { id: 4, name: "MR-04 ANTI-CORRUPTION POLICY.pdf", link: "/pdf/RJCPL-MR-04 ANTI-CORRUPTION POLICY.pdf" },
                { id: 5, name: "MR-05 RESPONSIBLE JEWELLERY COUNCIL POLICY.pdf", link: "/pdf/RJCPL-MR-05 RESPONSIBLE JEWELLERY COUNCIL POLICY.pdf" },
                { id: 6, name: "MR-06 RESPONSIBLE MARKETING POLICY.pdf", link: "/pdf/RJCPL-MR-06 RESPONSIBLE MARKETING POLICY.pdf" },
                { id: 7, name: "MR-07 RESPONSIBLE SOURCING POLICY.pdf", link: "/pdf/RJCPL-MR-07 RESPONSIBLE SOURCING POLICY.pdf" },
            ],
            policies2: [
                { id: 8, name: "MR-08 KYC-POLICY.pdf", link: "/pdf/RJCPL-MR-08 KYC-POLICY.pdf" },
                { id: 9, name: "MR-09 SUPPLY CHAIN POLICY.pdf", link: "/pdf/RJCPL-MR-09 SUPPLY CHAIN POLICY.pdf" },
                { id: 10, name: "MR-10 PRODUCT DISCLOSURE POLICY.pdf", link: "/pdf/RJCPL-MR-10 PRODUCT DISCLOSURE POLICY.pdf" },
                { id: 11, name: "MR-11 EMPLOYEE GRIEVANCE-PROCEDURE-COMPANY-POLICY.pdf", link: "/pdf/RJCPL-MR-11 EMPLOYEE GRIEVANCE-PROCEDURE-COMPANY-POLICY.pdf" },
                { id: 12, name: "MR-12 CUSTOMER AND SUPPLIER GRIEVANCE POLICY AND PROCEDURE.pdf", link: "/pdf/RJCPL-MR-12 CUSTOMER AND SUPPLIER GRIEVANCE POLICY AND PROCEDURE.pdf" },
                { id: 13, name: "MR-13 HUMAN RIGHTS POLICY.pdf", link: "/pdf/RJCPL-MR-13 HUMAN RIGHTS POLICY.pdf" },
            ],
            RJC: [
                { id: 1, name: "Anual Report COP-2024.pdf", link: "/pdf/Anual Report COP-2024.pdf" },
                { id: 2, name: "OECD-Responsible Compliance Report.pdf", link: "/pdf/OECD-Responsible Compliance Report.pdf" },
                { id: 3, name: "Provenance  Complian Form.pdf", link: "/pdf/Provenance  Complian Form.pdf" },
            ],
        };
    },
    methods: {
        handleViewFile(url) {
            console.log("url ->", url)
            window.open(url, '_blank');
        },
        downloadFile(link) {
            const anchor = document.createElement("a");
            anchor.href = link;
            anchor.download = link.split('/')[2];
            anchor.click();
        },
    },
};


</script>

<style lang="scss" scoped>
@import "@/assets/scss/order";
@import "@/assets/scss/home";
@import "@/assets/scss/about";
@import "@/assets/scss/main";
@import "@/assets/scss/sustainable";
</style>
<template>
  <div id="footer" class="footer-bg-one">
    <div class="text-point-auto pt-4 pb-2">
      <ul class="">
        <li @click="action_button((activeBtn = 'btn1'))">
          <a href="/">{{ $t("message.HOME") }}</a>
        </li>
        <li @click="action_button((activeBtn = 'btn2'))">
          <a href="/about">{{ $t("message.ABOUT") }}</a>
        </li>
        <li @click="action_button((activeBtn = 'btn3'))">
          <a href="/sustainable">{{ $t("message.SUSTAINABLE") }}</a>
        </li>
        <li @click="action_button((activeBtn = 'btn4'))">
          <a href="/showcase">{{ $t("message.SHOWCASE") }}</a>
        </li>
        <li @click="action_button((activeBtn = 'btn5'))">
          <a href="/service">{{ $t("message.SERVICE") }}</a>
        </li>
        <li @click="action_button((activeBtn = 'btn6'))">
          <a href="/order">{{ $t("message.ORDER") }}</a>
        </li>
        <li @click="action_button((activeBtn = 'btn7'))">
          <a href="/contact">{{ $t("message.CONTACT") }}</a>
        </li>
      </ul>
    </div>

    <div class="d-flex justify-content-center footer-bg-two">
      <div class="my-4">
        <span :style="{ color }">{{ $t("message.FOOTTER") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  mounted() {
    this.set();
  },
  props: ["title", "color"],
  methods: {
    set() {
      let get_Btn = JSON.parse(localStorage.getItem("activeBtn_set"));
      if (get_Btn === null) {
        var button_get = "btn1";
        this.activeBtn = button_get;
        return;
      }
      this.activeBtn = get_Btn;
      console.log(get_Btn);
      // localStorage.clear();
      return;
    },
    action_button(activeBtn) {
      localStorage.setItem("activeBtn_set", JSON.stringify(activeBtn));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/footer";
</style>

export default {
  message: {
    TH: "ไทย",
    EN: "อังกฤษ",
    HOME: "หน้าหลัก",
    ABOUT: "เกี่ยวกับ",
    SHOWCASE: "สินค้าแนะนำ",
    SERVICE: "บริการ",
    ALIBABA: "อาลีบาบา",
    CONTACT: "ติดต่อเรา",
    ORDER: "สั่งซื้อและจัดส่ง",
    ORDERFOTER: "บริการของเรา",
    FOOTTER: "(ไทย)Copyright © 2009 Bella Jewelry.com All rights reserved.",
    /* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
    // Home
    content1: "(ไทย)COMPANY",
    content2: "(ไทย)BELLA JEWELRY",
    content3:
      " (ไทย)A fine Jewelry manufacture for gold and silver for OEM Designers and wholesalers around the world.",
    /* ------------------ */
    content4: "(ไทย)BRIEF HISTORY",
    content5:
      "(ไทย)With over 70 years of experience in manufacturing fine gold jewelry for top-end locals and over 40 years of export experience has transformed us to a leading In-House Full-service OEM Factory for lading jewelry designers and wholesalers for Medium to Hi-End market around the word with exceptional craftsmanship quality.",
    content6: "(ไทย)READ MORE",
    /* ------------------ */
    content7: "(ไทย)DESIGNER WELCOME",
    content8:
      "(ไทย)Our Medium Size Factory is advantageous and consists of more than 270 skillful artisans and Full house service for every manufacturing process. In particular, Stone Cutting which is a rare process in general jewelry factories. Therefore, Bella Jewelry has been selected by top-class designers because we are approachable and flexible combined with professionals whether it be quality, unique design, workflow and price.",
    content9: "(ไทย)VIEW MORE",
    /* -------Card_About.vue----------- */
    content10: "(ไทย)COMMITMENT",
    /* --- */
    content11: "1",
    content12: "(ไทย)QUALITY",
    content13:
      "(ไทย)Quality has always been our top priority. We have many loyal customers who have enjoy our products and services quality so much that they keep ordering from our factory for over 10 or 20 years.",
    /* --- */
    content14: "2",
    content15: "(ไทย)EXCLUSIVITY",
    content16:
      "(ไทย)Having an In-House Full Service from sketching to stone cutting can guarantee exclusivity of your design and able to customize to meet special requirement of your product.",
    /* --- */
    content17: "3",
    content18: "(ไทย)FLEXIBILITY",
    content19:
      "(ไทย)We have prioritized flexibility in communication, design and manufacturing process as well as to be professional manufacture and high quality to meet the needs of our customers as much as possible.",
    /* --- */
    content20: "4",
    content21: "(ไทย)TRUSTWORTHY",
    content22:
      "(ไทย)We have prioritized honesty and faithfulness to ensure all customers that they will receive the highest quality products at a reasonable price and are the most trustworthy.",
    /* ------------------ */
    content23: "(ไทย)SHOWCASE",
    content24: "(ไทย)VIEW MORE",
    //compontent gettouch
    content25: "(ไทย)GET IN TOUCH WITH US",
    content26: "(ไทย)Please contact for more information",
    content27: "(ไทย)GET IN TOUCH",
    /* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
    //ABOUT
    content28: "(ไทย)ABOUT US",
    content29:
      "(ไทย)A fine Jewelry manufacture for gold and silver for OEM Designers and wholesalers around the world.",
    content30: "(ไทย)HISTORY",
    content31: "(ไทย)COMMITMENT",
    content32: "(ไทย)WHY BELLA",
    content33: "(ไทย)OUR CLIENTS AND PARTNERS",
    content36:
      "(ไทย)Founded in early 1940 over 70 years ago as a gold smiths and has grown to an export factory Bella Jewelry (Factory), Ltd in 1979 where we have been focused on manufacturing and exporting 8K-18K Gold and Silver Fine Jewelry for customers around the world for over 40 years. Our factory consists of diverse ages in the workplace and currently employ over 250 talented artisans which many of them have been working with our factory over 30 or 40 years. Presently, Bella Jewellery (Factory), Ltd is an In-House Full-Service OEM factory that exports to North America 70% and Europe 30%. Our customers consist of famous wholesalers and designers all over the world.    ",
    /* --------whybella.vue---------- */
    content34: "(ไทย)QUALITY REPUTATION",
    content35:
      "(ไทย)With over 70 years of experience in manufacturing fine gold jewelry for top-end locals and nearly 30 years subcontracting for leading jewelry brands and factories around the world, we are well-known among the local Thai jewelry industry in providing exceptional craftsmanship quality.",
    content103:
      "(ไทย)Our commitment to provide highest quality craftsmanship is highlighted by our strict QC practice. Specially trained QC staff check every individual piece of jewelry at each manufacture step, from casting to packing for any imperfection of material or craftsmanship to ensure that every piece of jewelry we ship to you is top quality without any visible and measurable defect. This strict QC practice rewards us with a low rejection rate from customers.",
    content37: "(ไทย)DESIGNER WELCOME",
    content38:
      "(ไทย)Our Medium Size Factory is advantageous and consists of more than 270 skillful artisans and Full house service for every manufacturing process. In particular, Stone Cutting which is a rare process in general jewelry factories. Therefore, Bella Jewelry has been selected by top-class designers because we are approachable and flexible combined with professionals whether it be quality, unique design, workflow and price.",
    content39: "(ไทยFULL RANGE OF SERVICES WITH NO MINIMUM ORDER REQUIREMENT",
    content40:
      "(ไทยAll manufacturing processes are in-house including a stone cutting department to handle special cutting requirements. This facility enables us to fulfil orders of any size and from value-oriented markets to one-of-a-kind work of art.",
    /* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
    //SHOWCACS
    content41: "(ไทย)SHOWCASE",
    content42:
      "(ไทย)Bella Jewelry (Factory), Ltd is proficient of all types of Fine Jewelry from mass to one of a kind. We offer Full In-House service with our own custom stone cutting service with no minimum order and are capable of creating jewelries to meet the needs of your brand no matter whether you are a Designer or Wholesalers. ",
    content43: "(ไทย)Request for our lastest collection",
    // content43: "(ไทย)Visit our collections here",
    /* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
    //SERVICE
    //SERVICE
    content44: "(ไทย)WHAT WE DO",
    content45: "(ไทย)OUR SERVICE",
    // content45: "(ไทย)BELLA JEWELRY ’S SERVICE",
    content46:
      "(ไทย)We manufacture Jewellery for Jewellery brands and wholesalers around the world and we offer a complete range and one-stop service for fine jewellery designers, with flexible minimum order requirement",
    /* --------Card_Silder.vue---------- */
    content47: "(ไทย)PRODUCTION",
    content48: "(ไทย)METAL",
    content49:
      "(ไทย)925 Sterling Silver 8-18k Gold in Yellow, Pink and White Nickel free alloy for European Standard",
    content50: "(ไทย)COST AND MINIMUM ORDER",
    content51:
      "(ไทย)No minimum order. The Cost for a model building is between US$30 to US$200 depending on size and difficulty of the design",
    content52: "(ไทย)TERMS OF PAYMENT",
    content53:
      "(ไทย)30% deposit before production, 70% balance before shipping",
    /* ---------Services.vue---------- */
    content54: "(ไทย)SERVICES",
    content55:
      "(ไทย)We have in-house full service for jewelry manufacture comprehensively. With our 70 years’ experience 270 artisans combine with modern technology into the workflow. You are able to select our specific or entire workflow as follows",
    content56: "(ไทย)DESIGN EXECUTIONS INCLUDING SKETCHING / CAD CAM SERVICE",
    content57: "(ไทย)MOLD MAKING / MOLD CASTING / DIRECT-PRINT WAX",
    // content57: "(ไทย)MOLD MAKING / TYPO MOLD CASTING",
    content58: "(ไทย)STONE CUTTING",
    content59: "(ไทย)MOUNTING",
    content60: "(ไทย)POLISHING / PLATING",
    content61: "(ไทย)QUALITY CONTROL",
    /* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
    //ORDER
    content62: "(ไทย)ORDER & SHIPPING",
    content63: "(ไทย)Comprehensive Express Shipping Support Area for Customers",
    content64: "(ไทย)COST AND MINIMUM ORDER",
    content65:
      "(ไทย)For your utmost convenience, we have concluded how to order into 3 ways as follows",
    content66: "(ไทย)FREQUENTLY ASKED QUESTION",
    /* --- */
    content67: "1",
    content68: "(ไทย)Choose from our collections",
    content69: "(ไทย)VISIT BELLA’S JEWELRY ON ALIBABA",
    content699: "(ไทย)OR",
    content6999: "(ไทย)REQUEST FOR OUR LASTEST COLLECTION",

    content70: "2",
    content71:
      "(ไทย)Choose from our collections but personalize some of the jewelry",
    content72: "(ไทย)VISIT BELLA’S JEWELRY ON ALIBABA",
    content722: "(ไทย)OR",
    content7222: "(ไทย)REQUEST FOR OUR LASTEST COLLECTION",

    content73: "3",
    content74: "(ไทย)Custom Design is available",
    content75: "(ไทย)SEND US YOUR DESIGN",
    /* --- */
    content76: "(ไทย)Can i customise products?",
    content77:
      "(ไทย)Yes.You can either choose from our designs but personalize on stone selection, logo, plating, alloy color, gold karatage or send your custom made design.Let me know the specific customization requirements and I will get back to you as soon as possible.",

    content78: "(ไทย)Terms of payment",
    content79:
      "(ไทย)30% deposit before production, 70% balance before shipping",

    content80: "(ไทย)What is your Minimum Order Quantity and pricing?",
    content81:
      "(ไทย)No minimum order. The Cost for a model building is usually between US$30 to US$200 depending on size and difficulty of the design.",

    content82: "(ไทย)How long will it take to ship to my country?",
    content83:
      "(ไทย)It takes 4-5 weeks for production after the the deposit is received. Delivery. By insured FedEx, UPS or DHL",
    /* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
    //CONTACT
    content84: "(ไทย)CONTACT US",
    content85: "(ไทย)LET’S WORK TOGETHER",
    content86: "(ไทย)Locations",
    content87:
      "(ไทย)214,216 Charansanitwong 42 road, Charansanitwong, Bangplat, Bangyikan, Bangkok, Thailand 10700",
    content88: "(ไทย)Email",
    content89: "viruch@mondial.co.th",
    content90: "(ไทย)Phone",
    content91: "(ไทย)+662-423-0150 ต่อ 58",
    /* --- */
    content92: "(ไทย)Full Name",
    content93: "(ไทย)Your name",
    content94: "(ไทย)Email Address",
    content95: "(ไทย)Your email",
    content96: "(ไทย)Phone Number",
    content97: "(ไทย)Your phone number",
    content98: "(ไทย)Message",
    content99: "(ไทย)Your message",
    content100: "(ไทย)SEND MESSAGE",
    content110: "(ไทย)FAIR EXHIBITIONS",
    content111: "MOLD MAKING",
    content112: "MOLD CASTING",
    content113: "DIRECT-PRINT WAX",
    /* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
    //SUSTAINABLE
    content114: "(ไทย)Policies:",
    content115: "(ไทย)Bella Jewelry requires its suppliers to comply with the ethical principles set out in its Supplier Code of Conduct and to ensure that their own suppliers and subcontractors comply with these principles.",
    content116: "(ไทย)This Supplier Code of Conduct specifically specifies the obligation to respect social issues and to respect and adhere to moral and ethical values in the management of the company with regard to human rights, working conditions and environmental issues.",
    content117: "(ไทย)Bella Jewelry is aware of the potential social consequences of its activities, particularly in relation to the sourcing of raw materials. For this reason, the company has defined a responsible approach to sourcing and policies to operate ethically -from the sourcing of raw materials to the final product- align with its core vale sustainability, and respect for human rights. Particular attention is paid to the production of jewelry items:",
    content118: "(ไทย)Sustainable Development Objectives:",
    content119: "(ไทย)Bella Jewelry's commitment to sustainable development is reflected through its collaboration with partners to regulate the sourcing of metals and gemstones. By adhering to ISO 14001:2015 standards, Bella Jewelry ensures that its environmental management system is aligned with globally recognized practices. This certification is a pave way for the company to achieve its ambitious goal of sustainable development.",
    content120: "(ไทย)CSR Activities:",
    content121: "(ไทย)Bella Jewelry’s Corporate Social Responsibility (CSR) initiatives reflect its commitment to creating lasting, positive impacts on Thai society. With a focus on community advancement, Bella’s CSR activities are carefully designed to contribute to both short and long-term benefits for local communities. These efforts are driven by innovative strategies that foster collaboration not only within the company but also with business partners, and all stakeholders.",
    content122: "(ไทย)By integrating CSR into its core business operations, Bella Jewelry ensures its efforts align with both its values and business goals, fostering sustainable change. This collaborative approach helps build a meaningful legacy, benefiting both the company and the communities it serves.",
    
  },
};

<template>
    <div class="colab-template">
        <img class="img-colab" src="@/assets/image-home/RJC_logo_dark.png"/>
        <img class="img-colab" src="@/assets/image-home/ISO_certification.jpeg"/>
        <img class="img-colab sedex" src="@/assets/image-home/Sedex_logo.png"/>
    </div>
  </template>
  <script>
  export default {
    methods: {
    },
  };
  </script>
  <style lang="scss" scoped>
  @import "@/assets/scss/colab";
  @import "@/assets/scss/main";
  </style>
  